import React from "react";
import Logo from "../../../../assets/images/logos/logo_white.png";
import Plane from "../../../images/corona-island/icon-plane.svg";
import styles from "./results.module.scss";
import "../../../styles/corona-island.scss";

const Results = () => {

  const data = [
    {
      name: 'NICOLÁS PARDO'
    },
    {
      name: 'MARIA DIAZ'
    },
    {
      name: 'FRAN COLMA'
    },
    {
      name: 'LORE MORTAL'
    },
    {
      name: 'PIPE DELUSIO'
    }
  ];

  return (
    <section className={styles.container}>
      <div className={styles.content}>
        
        <h1>CONOCÉ A LOS 5 GANADORES</h1>
        
        {
          data.map(item => (
            <article>
              <div className={styles.ticket1}>
                <div className={styles.section1}>
                  <img src={Logo} alt='error'/>
                  <h4>| ISLAND</h4>
                </div>
                <div className={styles.section2}>
                  <div className={styles.name}>
                    <p>Nombre y Apellido</p>
                    <h2>{item.name}</h2>
                  </div>
                  <div>
                    <p>Destino</p>
                    <h3>CORONA ISLAND</h3>
                  </div>
                </div>
              </div>
              <div className={styles.ticket2}>
                <div className={styles.section1}>
                  <img src={Plane} alt='error'/>
                </div>
                <div className={styles.section2}>
                  <p>Fecha</p>
                  <h3>10 SEP 2022</h3>
                </div>
              </div>
            </article>
          ))
        }
        
      </div>
    </section>
  );
};

export default Results;
